import React from 'react';

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await require('intersection-observer');
  }
};

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment;
  return <Layout {...props}>{element}</Layout>;
}
